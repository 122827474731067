import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'VantagensOutros',
  components: { VueSlickCarousel },
  props: {
    title: {
      type: String,
      default: 'Vantagens que você só encontra aqui'
    }
  },
  data: () => ({
    vantagens: [
      {
        img: 'icones/layout/wi-fi',
        nome: 'Totalmente online',
        descrição: 'O processo de comprar é totalmente online e nós ainda levamos o carro até você. Simples assim.'
      },
      {
        img: 'icones/layout/steering-wheel',
        nome: '10 dias de Test Drive',
        descrição: 'Após a compra do seu carro, você recebe o benefício de um Test Drive de 10 dias ou 350 km. São 10 dias para conhecer seu novo carro e ter a certeza da sua escolha.'
      },
      {
        img: 'icones/layout/doc-icon',
        nome: '90 dias de garantia',
        descrição: 'Os carros comprados em nosso outlet têm 90 dias de garantia para motor e câmbio.'
      },
      {
        img: 'icones/layout/screw-nut',
        nome: 'Revisão 360°',
        descrição: 'Verificamos mais de 250 itens para que você se preocupe apenas em curtir seu carro seminovo.'
      },
      {
        img: 'icones/layout/prize-icon',
        nome: '100 dias de garantia total',
        descrição: 'O seu carro sai com 1 ano de Garantia Gestauto. Tranquilidade extra para você. Confira em nossa página de garantia.'
      },
      {
        img: 'icones/layout/car-icon',
        nome: 'Ajudamos a cuidar do carro',
        descrição: 'Você recebe um relatório completo do veículo e até e-mails periódicos com dicas de conservação.'
      }
    ],
    carrosselCards: {
      // lazyLoad: 'ondemand',
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      rows: 3,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 599,
          settings: {
            rows: 2,
            slidesToShow: 1.1,
            slidesToScroll: 1
          }
        }
      ]
    }
  })
}
